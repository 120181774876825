 <template>
	<div id="wrapper">

		<template v-if="$store.state.isAuthenticated">
            <AppMain /> 
        </template>
        <template v-else>
            <Login/>
        </template>
	</div>
</template>

<script>
import AppMain from './AppMain.vue';
import axios from "axios"
import Login from './pages/Login.vue';

export default {
    data() {
        return {
            login: null,
            user:{},
            isLogged: null,
        }
    },
    created(){
        this.checkLogin();
    },
    
    
    methods: {
        async checkLogin(){
            var config = {
                method: 'get',
                crossDomain: true,
                url: 'https://advantex.uz/auth/isLogged',
            };
            
            const res = await axios(config).then(res => res.data)
            if (!res.isLogged){
                this.$store.commit('logout')
                this.$router.push('Login');
            } else {
                this.user = JSON.parse(localStorage.getItem('user'))
                this.$store.commit('login', this.user)
            }

            
        },
    
    },
    components: {
        'AppMain': AppMain,
        'Login': Login,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
