import axios from 'axios';
import FormData from 'form-data';
axios.defaults.withCredentials = true;


export default class LoginService {

    async login(username, password) {
        var res = null;
        var data = new FormData();
        data.append('identity', username);
        data.append('password', password);

        var config = {
            method: 'post',
            url: 'https://advantex.uz/auth/login',
            data: data,
        };


        try {
            const ress = await axios(config).then(ress => ress.data);
            console.log(ress)
            return ress
        }
        catch (error) {
            console.log(error)
        }
        return res;

    }

    logout() {

    }

    async isLogged() {

        var config = {
            method: 'get',
            url: 'https://advantex.uz/auth/isLogged',
        };

        axios(config).then(response => {
            console.log(response)
            return response
        })
            .catch(function (error) {
                console.log(error);
            });
    }

}