import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';
// import LoginService from './service/LoginService';

const routes = [

    {
        path: '/currency',
        name: 'currency',
        component: () => import('./components/CurrencyTable.vue'),
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('./components/OrderTable.vue'),
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: () => import('./components/GalleryTable.vue'),
    },
    {
        path: '/coupons',
        name: 'Coupons',
        component: () => import('./components/CouponTable.vue'),
    },
    {
        path: '/brands',
        name: 'Brands',
        component: () => import('./components/ProductBrands.vue'),
    },
    {
        path: '/industries',
        name: 'Industries',
        component: () => import('./components/IndustriesTable.vue'),
    },
    {
        path: '/producttypes',
        name: 'Product types',
        component: () => import('./components/ProductTypesTable.vue'),
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('./components/BlogTable.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./pages/Login.vue'),
    },
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/companies',
        name: 'companies',
        component: () => import('./components/CompaniesDemo.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('./components/UsersDemo.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/machines',
        name: 'machines',
        component: () => import('./components/MachinesDemo.vue'),
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('./components/ProductsDemo.vue'),
    },
    {
        path: '/reserve',
        name: 'reserve',
        component: () => import('./components/ReservedTable.vue'),
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: () => import('./components/InvoicesTable.vue'),
    },
    {
        path: '/newinvoice',
        name: 'newinvoice',
        component: () => import('./components/newInvoice.vue'),
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue'),
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue'),
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue'),
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('./utilities/DisplayDemo.vue'),
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('./utilities/FlexBoxDemo.vue'),
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('./utilities/TextDemo.vue'),
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('./utilities/GridDemo.vue'),
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('./utilities/SpacingDemo.vue'),
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('./utilities/ElevationDemo.vue'),
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('./utilities/Typography.vue'),
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/CalendarDemo.vue'),
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
// router.beforeEach((to, from, next) => {
//     const publicPages =['/login'];
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = true;
//     if (authRequired && !loggedIn){
//         return next('/login');
//     }
//     next();
// })

export default router;
