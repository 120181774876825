import { createStore } from "vuex";

export default createStore({
    state: {
        isAuthenticated: false,
        user: {
            first_name: '',
            id:'',
            last_name:''
        },
    },
    mutations: {
        initializeStore() {
            this.state.isAuthenticated = localStorage.getItem('isAuthenticated')
        },
        login(state, user) {
            state.isAuthenticated = true
            state.user = user
            localStorage.setItem('isAuthenticated', state.isAuthenticated)
            localStorage.setItem('user', JSON.stringify(state.user))
        },

        logout() {
            this.state.isAuthenticated = false
            this.state.user = {}
            localStorage.setItem('isAuthenticated', this.state.isAuthenticated)
            localStorage.setItem('user', JSON.stringify(this.state.user))
        },
    },
    actions: {},
    modules: {},
})