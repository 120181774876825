<template>
	<div class="layout-profile">
		<div>
			<img src="assets/layout/images/avatar_4.png" alt="" />
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{ user.email }}</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
                <!-- <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li> -->
                <li><button @click="logout()" class="p-link"><i class="pi pi-fw pi-power-off"></i><span>Logout</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		data() {
			return {
				expanded: false,
				user: {
					first_name:''
				}
			}
		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			
			logout: function(){
				var config = {
					method: 'get',
					url: 'https://advantex.uz/auth/logout',
				};
				
				const ress = axios(config).then(ress => ress.data);
			
				console.log(ress)
				if (ress){
					this.$store.commit('logout')
					this.$router.push('/login')
				} else{
					console.log(ress)
				}
			}
		
		},
		mounted() {
			this.user = this.$store.state.user
		}
	}
</script>

<style scoped>

</style>