<template>
	<div class="page-login p-d-flex p-align-center p-flex-wrap p-justify-center" style="
		min-height: 100vh;
		width: 100%;
		">
		<div class="p-col-6 p-col-align-center" style="padding:77px 55px 33px">
			<div class="">
				<form @submit.prevent="handleSubmit">
					<div class="card p-fluid">
						<h5>Login</h5>

						<Toast />

						<div class="p-field">
							<label for="email">Email</label>
							<InputText id="email" type="text" v-model="username" />
						</div>
						<div class="p-field">
							<label for="password">Password</label>
							<InputText id="password" type="password" v-model="password" />
						</div>
						<Button @click="login()" label="Log in" class="p-button-raised p-mr-2 p-mb-2" />
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import LoginService from '../service/LoginService'
import axios from 'axios'
import FormData from 'form-data'
export default {
	name: 'Login',
	data() {
		return {
			username: "",
			password: "",
			logged: true,
			user: {
				name: ''
			}
		}
	},
	loginService: null,

	created() {

		this.loginService = new LoginService();
		this.checkLogin()

	},

	methods: {
		async checkLogin() {
			var config = {
				method: 'get',
				crossDomain: true,
				url: 'https://advantex.uz/auth/isLogged',
			};

			const res = await axios(config).then(res => res.data)
			if (!res.isLogged) {
				this.$store.commit('logout')
			} else {
				this.$router.push('/')
			}


		},
		handleSubmit() {

		},

		login: async function () {
			if (this.username != "" && this.password != "") {

				var data = new FormData();
				data.append('identity', this.username);
				data.append('password', this.password);
				data.append('remember', false);

				var config = {
					method: 'post',
					url: 'https://advantex.uz/auth/login',
					data: data,
				};

				const ress = await axios(config);

				// console.log(ress)
				if (ress.data.isLogged) {
					this.user = ress.data.user_data;
					this.$store.commit('login', this.user)
					this.$router.push('/')
				} else {
					this.$toast.add({ severity: 'error', summary: 'Login Error', detail: ress.data.message, life: 3000 })
				}


			} else {
				this.$toast.add({ severity: 'error', summary: 'Login Error', detail: "Please enter username & password", life: 3000 })
			}
		},

	}


}
</script>

<style scoped lang="scss">
</style>
